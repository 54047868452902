@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	scroll-margin-top: 80px;
}
html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	/* padding: 0;
	 
	
	
	box-sizing: border-box; */

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
